.container-fluid {
  padding: 0px !important;
  overflow: hidden;
}
.container-fluid .hero {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;
  background-position: center center;
}
.container-fluid .simple-hero img {
  width: 100%;
}

.headline-content {
  position: relative;
}
@media (max-width: 768px) {
  .headline-content {
    padding-top: 55px;
  }
}
.headline-content:before {
  content: "";
  position: absolute;
  left: -95px;
  top: 10px;
  width: 50px;
  height: 50px;
}
@media (max-width: 768px) {
  .headline-content:before {
    left: 0px;
    top: 0px;
  }
}
.headline-content.icon-green:before {
  content: "";
  background: url("../block_templates/images/icons/pf_arrow_green.png");
  background-size: 50px 50px;
  background-repeat: no-repeat;
}
.headline-content.icon-orange:before {
  content: "";
  background: url("../block_templates/images/icons/pf_arrow_orange.png");
  background-size: 50px 50px;
  background-repeat: no-repeat;
}
.headline-content.icon-purple:before {
  content: "";
  background: url("../block_templates/images/icons/pf_arrow_purple.png");
  background-size: 50px 50px;
  background-repeat: no-repeat;
}
.headline-content.icon-yellow:before {
  content: "";
  background: url("../block_templates/images/icons/pf_arrow_yellow.png");
  background-size: 50px 50px;
  background-repeat: no-repeat;
}

img {
  max-width: 100%;
}

.has-black-background-color {
  background-color: #000000;
}

.has-white-background-color {
  background-color: #ffffff;
}

.has-red-background-color {
  background-color: #ec1c24;
}

.has-blue-background-color {
  background-color: #007599;
}

.has-light-grey-background-color {
  background-color: #f3f3f3;
}

.has-dark-grey-background-color {
  background-color: #737373;
}