.headline-content {
  position: relative;
  @media (max-width: 768px) {
    padding-top: 55px;
  }
  &:before {
    content:'';

    position:absolute;
    left:-95px;
    top:10px;
    width: 50px;
    height: 50px;
    @media (max-width: 768px) {
      left:0px;
      top:0px;
    }
  }
  &.icon-green {
    &:before {
      content:'';
      background: url('../block_templates/images/icons/pf_arrow_green.png');
      background-size: 50px 50px;
      background-repeat: no-repeat;
    }
  }
  &.icon-orange {
    &:before {
      content:'';
      background: url('../block_templates/images/icons/pf_arrow_orange.png');
      background-size: 50px 50px;
      background-repeat: no-repeat;
    }
  }
  &.icon-purple {
    &:before {
      content:'';
      background: url('../block_templates/images/icons/pf_arrow_purple.png');
      background-size: 50px 50px;
      background-repeat: no-repeat;
    }
  }
  &.icon-yellow {
    &:before {
      content:'';
      background: url('../block_templates/images/icons/pf_arrow_yellow.png');
      background-size: 50px 50px;
      background-repeat: no-repeat;
    }
  }
}
